import type { InferGetServerSidePropsType, GetServerSidePropsContext } from "next"
import { getStoryblokApi, StoryblokComponent, useStoryblokState, ISbStoriesParams } from "@storyblok/react"
import { createServerSupabaseClient } from '@supabase/auth-helpers-nextjs'
import { connect } from "react-redux"
import Head from 'next/head'

import { wrapper } from '@/services/store'

export const getServerSideProps = wrapper.getServerSideProps(store => async(context: GetServerSidePropsContext) => {
  const supabaseServerClient = createServerSupabaseClient(context)

  const page = context.params?.page ? (context.params?.page as string[]).join("/") : "home"

  const { data: { user } } = await supabaseServerClient.auth.getUser() 

  // TODO: Update for Movement integration
  if (user && !user?.user_metadata.chargebee_id && page !== 'account/add') {
    /*
    return {
      redirect: {
        destination: "/account/add",
        permanent: false,
      }
    }
    */
  }

  const insideStoryblok = context.query._storyblok

  if (!insideStoryblok && (page === "about" || page === "contemplation" || page === "care" || page === 'faqs')) {
    context.res.setHeader(
      'Cache-Control',
      'public, s-maxage=600, stale-while-revalidate=600'
    )
  }
  
  const resolveRelations = [
    'blogRow.articles',
    'heroPractice.practice',
    'recommendedRow.practices',
    'practiceRow.practices',
    'tagsRow.tags',
    'topicsRow.topics',
    'exploreCTA.tags',
    'filtersBlock.categories',
  ]
  
  const sbParams: ISbStoriesParams = {
    version: insideStoryblok ? 'draft' : 'published',
    resolve_relations: resolveRelations
  }

  const { data } = await getStoryblokApi().get(`cdn/stories/${page}`, sbParams)

  const { seoTitle, seoDescription, seoImage } = data.story.content

  return {
    props: {
      seo: {
        title: seoTitle !== '' ? seoTitle : `${data.story.name} | Rise`,
        description: seoDescription !== '' ? seoDescription : 'Rise is an online wellness and fitness platform. A space to enliven the senses, create rituals and move deeper.',
        image: seoImage.filename ? seoImage.filename : '/images/thumbnail.jpg',
      },
      story: data ? data.story : false,
    },
  }
})

const Page = ({ story: theStory, seo }: InferGetServerSidePropsType<typeof getServerSideProps | any>) => {
  const story = useStoryblokState(theStory)

  const canonicalUrl = `https://rise-practice.com/${theStory.full_slug !== 'home' ? theStory.full_slug : ''}`

  return (
    <>
      <Head>
        <title>{seo.title}</title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="canonical" href={canonicalUrl} />
        <meta name="description" content={seo.description} />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />
        <meta name="facebook-domain-verification" content="llnvqn19atpxt0blmzzlvpl6xd08kb" />
        <meta name="google-site-verification" content="PPVUe4vRsivfp9xwj8u5PCmSLvHjQ9naDnKBmn6GSIk" />
      </Head>
      <StoryblokComponent blok={story.content} />
    </>
  )
}

export default connect()(Page)
